body {
    font-family: 'Philosopher', cursive;
}
p,
h2,
h3,
h5,
address,
.card-body > .card-title {
    font-family: 'Philosopher', cursive;
}

.p-title {
    font-family: 'Philosopher', cursive;
    font-style: italic;
    font-size: small;
}

h1 {
    font-family: 'Philosopher', cursive;
    font-size: 40px;
}

.card-title {
    font-weight: 500;
    font-size: 1.25rem;
}

.card-body{
font-family: 'Philosopher', cursive;
}

h2,h3,h5 {
    color: white;
}

.center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 30%;
  }

.row-content {
    padding: 20px 0;
    border-bottom: 1px ridge;
    min-height: 200px;
}

.site-footer {
    background-image: linear-gradient(to right, #e12f53, #e32222, #f18509);
    padding: 20px 0;
    margin-top: 2rem;
}

.navbar-brand {
    max-width: 4rem;
    margin-right: 2rem;
}

.navbar-brand img {
    width: 80%;
    height: 80%;
    margin-right: 1rem;
    max-width: 4rem;
}

.navbar-dark {
    background-color: #f9f6fa;
    background-image: linear-gradient(to right, #e12f53, #e32222, #f18509);
    margin-bottom: 0.5rem;
    min-height: 3rem;
}

.card-text, p {
    font-size: 125%;
}

.displayed {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
} 

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.facebook {
    color: whitesmoke;
}

.toast-message {
    color: #fff;
    font-size: 20px;
    width: 34vw;
    padding: 30px 20px;
}

.page-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease-out;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: 300ms ease-in;
}
